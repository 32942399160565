import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_ENVIRONMENT } from "$env/static/public";

Sentry.init({
  dsn: "https://d54d1508dcddace526318fa3077be3d3@o4507835277377536.ingest.us.sentry.io/4507835278622720",
  tracesSampleRate: 1.0,

  environment: PUBLIC_ENVIRONMENT,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],

  enabled: PUBLIC_ENVIRONMENT !== "development",
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
